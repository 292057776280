import React from "react"
import clsx from "clsx"
import { Typography, Paper, makeStyles } from "@material-ui/core"
import grey from "@material-ui/core/colors/grey"

import {
  Redirect,
  Route,
  Switch,
  NavLink,
  useRouteMatch,
} from "react-router-dom"

import Reports from "./Reports"
import UsersTable from "./tables/Users"
import Devices from "./Devices"
import Domains from "./Domains"

export function Tabs({ organisation }) {
  const classes = useStyles()
  const { path, url } = useRouteMatch()

  const TabLink = ({ to, children }) => (
    <NavLink
      to={to}
      activeClassName={classes.activeTab}
      className={classes.tabLink}
    >
      <Typography variant="h6" className={classes.tabLabel}>
        {children}
      </Typography>
    </NavLink>
  )

  return (
    <>
      <div className={classes.tabs}>
        <TabLink to={`${url}/reports`}>Reports</TabLink>
        <TabLink to={`${url}/users`}>Users</TabLink>
        <TabLink to={`${url}/devices`}>Devices</TabLink>
        <TabLink to={`${url}/domains`}>Domains</TabLink>
      </div>

      <Paper className={clsx(classes.content, "slowfadein")}>
        <Switch>
          <Route path={`${path}/reports`}>
            <Reports organisation={organisation} />
          </Route>
          <Route path={`${path}/users`}>
            <UsersTable
              organisationId={organisation.id}
              organisationName={organisation.displayName}
            />
          </Route>
          <Route path={`${path}/devices`}>
            <Devices id={organisation.id} />
          </Route>
          <Route path={`${path}/domains`}>
            <Domains id={organisation.id} />
          </Route>
          <Redirect from={path} to={`${path}/reports`} />
        </Switch>
      </Paper>
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  tabs: {
    margin: spacing(2),
    marginTop: spacing(4),
    display: "flex",
    borderBottom: `1px solid lightgrey`,
    width: "100%",
  },
  tabLink: {
    color: grey[500],
    marginRight: spacing(2),
  },
  tabLabel: {
    padding: 4,
    fontSize: "1.1em",
  },
  activeTab: {
    color: palette.primary.main,
    borderBottom: `2px solid ${palette.primary.main}`,
  },
  content: {
    minHeight: 200,
    width: "100%",
    margin: "0 1em",
  },
  settings: {
    margin: spacing(2),
  },
}))
