import React, { useState, useRef } from "react"
import axios from "axios"
import {
  FormControl,
  Paper,
  Typography,
  InputLabel,
  OutlinedInput,
  ListItem,
  makeStyles,
} from "@material-ui/core"

import SearchIcon from "@material-ui/icons/Search"
import grey from "@material-ui/core/colors/grey"

const ORG_API = process.env.REACT_APP_ORG_URL

const args = {
  limit: 10,
  offset: 0,
  sortBy: {
    key: "displayName",
    value: "asc",
  },
}
const getStyles = makeStyles(theme => ({
  optionsList: {
    maxHeight: 200,
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    zIndex: 2,
    width: `${100 - theme.spacing(1)}%`,
  },
  option: {
    backgroundColor: theme.palette.background.paper,
  },
  searchIcon: { color: grey[500] },
  status: {
    position: "absolute",
    display: "block",
  },
}))

function useDebounce(fn, delay) {
  const lastCall = useRef(0)
  const lastTimer = useRef(null)

  const clear = () => {
    clearTimeout(lastTimer.current)
    lastCall.current = Date.now()
  }

  const debouncedFn = value => {
    const now = Date.now()
    const diff = now - lastCall.current
    if (diff > delay) {
      fn(value)
      clear()
      return
    }
    clearTimeout(lastTimer.current)
    lastTimer.current = setTimeout(() => {
      fn(value)
      clear()
    }, delay)
  }

  return debouncedFn
}

export default ({ initialValue, setValue: setValueInParent }) => {
  const styles = getStyles()
  const [searchBy, setSearchBy] = useState(initialValue)
  const [optionsVisible, setVisibility] = useState(false)

  // type state =
  //   | {s: IDLE | LOADING | ERROR | EMPTY}
  //   | {s: ERROR, e: Error}
  //   | {s: SUCCESS, records: Company[]}
  const [state, setState] = useState({ s: "IDLE" })

  const fetchCompanies = searchTerm => {
    !state.records && setState({ s: "LOADING" })

    args.searchBy = searchTerm
    axios
      .post(ORG_API, args)
      .catch(e => setState({ s: "ERROR", e }))
      .then(({ data: { records } }) =>
        records.length > 0
          ? setState({ s: "SUCCESS", records })
          : setState({ s: "EMPTY" }),
      )
  }

  const refreshList = useDebounce(fetchCompanies, 500)

  const handleSearchTermChange = e => {
    const searchTerm = e.target.value.trim()
    setValueInParent("")
    if (searchTerm.length === 0) {
      setState({ s: "IDLE" })
      setSearchBy("")
      return
    }
    // don't throw away spaces in the actual search term
    setSearchBy(e.target.value)
    refreshList(searchTerm)
  }

  const selectItem = (id, name) => {
    setSearchBy(name)
    setVisibility(false)
    setValueInParent(id)
  }

  const SearchStatus = ({ text }) => (
    <Typography variant="caption" className={styles.status}>
      {text}
    </Typography>
  )

  const Options = () => {
    switch (state.s) {
      case "IDLE":
        return <SearchStatus text="Type to search" />

      case "LOADING":
        return <SearchStatus text="Fetching matches..." />

      case "ERROR":
        return <SearchStatus text="Error fetching list" />

      case "EMPTY":
        return <SearchStatus text="No matches" />

      case "SUCCESS":
        return (
          <Paper className={styles.optionsList} elevation={3}>
            {state.records.map(record => (
              <ListItem
                className={styles.option}
                key={record.id}
                button
                onClick={() => selectItem(record.id, record.displayName)}
              >
                {record.displayName}
              </ListItem>
            ))}
          </Paper>
        )

      default:
        throw new Error("Invalid state")
    }
  }

  const handleBlur = () => {
    // Hack: delay enough to allow time for selectItem to process

    setTimeout(() => {
      setVisibility(false)
      if (state.s === "EMPTY") setSearchBy("")
    }, 1000)
  }

  return (
    <>
      <FormControl variant="outlined" spacing={3} size="small" fullWidth>
        <InputLabel htmlFor="assign-company">Choose a company...</InputLabel>
        <OutlinedInput
          id="assign-company"
          onBlur={handleBlur}
          labelWidth={150}
          value={searchBy}
          autoComplete="off"
          onFocus={_ => setVisibility(true)}
          onChange={handleSearchTermChange}
          endAdornment={<SearchIcon className={styles.searchIcon} />}
        />
      </FormControl>

      {optionsVisible && <Options />}
    </>
  )
}
