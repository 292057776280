import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"

import { Link } from "react-router-dom"
import { format } from "date-fns"

import { TableRow, TableCell, Chip, Grid, Tooltip } from "@material-ui/core"
import {
  MailOutline as MailIcon,
  VpnKey as GenericLicenseIcon,
} from "@material-ui/icons"
import { TruncateAsRequired } from "../../../layout/TruncateAsRequired"

const studioIconPath = require("../../../assets/icons-studio-square-a.svg")
const excelPluginIconPath = require("../../../assets/icons-excel-square-a.svg")
const exchangeAppIconPath = require("../../../assets/arria-exchange.svg")
const biIconPath = require("../../../assets/icons-bi-square-a.svg")
const biContributorIconPath = require("../../../assets/icons-bi-contributor-square-a.png")

const docsApp =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAdElEQVRIie2SSw6AIAxEB+PpPJn39CC6RULsTIsEEl7Cht9LpwU6cwC4ANziovF8LgnkBxZby888AjaikxWWESl9oCQRASWJCkxJC8FLkiqCfD8ysgkYYEzD7OL9MtKcapzDVSA3vXsFXxlb56sHPuYX/M4DQ6dpfl/mnHIAAAAASUVORK5CYII="

const ICONS = {
  "studio-app": studioIconPath,
  "exchange-app": exchangeAppIconPath,
  "docs-app": docsApp,
  "excel-plugin": excelPluginIconPath,
  "excel-standard": excelPluginIconPath,
  "excel-enterprise-trial": excelPluginIconPath,
  "excel-standard-narratives": excelPluginIconPath,
  "excel-custom": excelPluginIconPath,
  "excel-premium": excelPluginIconPath,
  "excel-finance-essentials": excelPluginIconPath,
  "excel-default": excelPluginIconPath,
  "bi-creator-1-basic": biIconPath,
  "bi-creator-2-intermediate": biIconPath,
  "bi-creator-3-premium": biIconPath,
  "bi-creator-4-custom": biIconPath,
  "bi-default": biIconPath,
  "bi-contributor-1-basic": biContributorIconPath,
}

const NoData = () => (
  <span style={{ color: "grey", fontStyle: "italic" }}>None assigned</span>
)

const Cell = ({ title, children }) => (
  <TableCell>
    <TruncateAsRequired title={title || ""}>{children}</TruncateAsRequired>
  </TableCell>
)

const Entitlements = ({ entitlements }) => {
  if (!entitlements) return <NoData />

  return entitlements?.map((entitlement, i) => {
    const entitlementIcon = ICONS[entitlement.entitlement]
      ? ICONS[entitlement.entitlement]
      : entitlement.entitlement.startsWith("excel-")
      ? ICONS["excel-default"]
      : entitlement.entitlement.startsWith("bi-")
      ? ICONS["bi-default"]
      : null
    return (
      <Tooltip title={entitlement.displayName} key={i}>
        {entitlementIcon ? (
          <img
            style={{ width: "24px", marginRight: "10px" }}
            src={entitlementIcon}
            alt={entitlement}
          />
        ) : (
          <GenericLicenseIcon />
        )}
      </Tooltip>
    )
  })
}

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const UserTableRow = ({
  userId,
  displayName,
  emailAddress,
  organisationId,
  organisationName,
  accountStatus,
  biIdentityId,
  primaryIdentityId,
  identityProvider,
  entitlements,
  createdDate,
}) => {
  const classes = useStyles()

  let accountStatusStyle
  switch (accountStatus) {
    case "ACTIVE":
      accountStatusStyle = classNames(classes.chip, classes.success)
      break
    case "SUSPENDED":
      accountStatusStyle = classNames(classes.chip, classes.warning)
      break
    case "DISABLED":
      accountStatusStyle = classNames(classes.chip, classes.disabled)
      break
    default:
      accountStatusStyle = classes.chip
      break
  }

  const primaryIdentityIdStyle = classNames(classes.chip, classes.warning)
  const federatedIdentityIdStyle = classNames(
    classes.chip,
    classes.primary,
    classes.federated,
  )

  const emailLink = `mailto:${emailAddress}`
  return (
    <TableRow className="slowfadein">
      <Cell title={displayName}>
        <Link to={"/user/" + userId}>{displayName}</Link>
      </Cell>
      <Cell title={emailAddress}>
        <a
          href={emailLink}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.email}
        >
          <MailIcon className={classes.mailIcon} />
          {emailAddress}
        </a>
      </Cell>
      <Cell title={organisationName}>
        {organisationName ? (
          <Link to={"/company/" + organisationId}>{organisationName}</Link>
        ) : (
          "—" // em dash
        )}
      </Cell>
      <TableCell align="center">
        <Chip
          label={accountStatus}
          size="small"
          className={accountStatusStyle}
        />
      </TableCell>
      <TableCell>
        <Grid container spacing={1} justify="center" alignItems="center">
          {primaryIdentityId &&
            identityProvider === "COGNITO_PRIMARY_USERPOOL" && (
              <Grid item xs={4}>
                <Tooltip title="Primary Identity">
                  <Chip
                    label="P"
                    size="small"
                    align="right"
                    className={primaryIdentityIdStyle}
                  />
                </Tooltip>
              </Grid>
            )}
          {identityProvider === "EXTERNAL_PROVIDER" && (
            <Grid item xs={4}>
              <Tooltip title="Federated Identity">
                <Chip
                  label="F"
                  size="small"
                  align="right"
                  className={federatedIdentityIdStyle}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </TableCell>
      <TableCell align="right">
        <Entitlements entitlements={entitlements} />
      </TableCell>
      <TableCell align="right">
        {format(nowUTC(createdDate), "MMMM dd, yyyy")}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: "8pt",
    fontWeight: 500,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    width: "24px",
  },
  disabled: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
    width: "24px",
  },
  federated: {
    backgroundColor: "#774ebf",
    color: "#fff",
    width: "24px",
  },
  mailIcon: {
    fontSize: "16px",
    verticalAlign: "middle",
    marginRight: "4px",
    color: "rgba(0, 0, 0, 0.38)",
  },
  email: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}))

UserTableRow.propType = {
  userId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  organisationId: PropTypes.string,
  organisationName: PropTypes.string,
  accountStatus: PropTypes.string.isRequired,
  biIdentityId: PropTypes.string,
  primaryIdentityId: PropTypes.string,
  createdDate: PropTypes.string.isRequired,
}

export default UserTableRow
