import React, {
  useReducer,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from "react"
import axios from "axios"
import {
  AsYouTypeFormatter,
  PhoneNumberFormat,
  PhoneNumberUtil,
} from "google-libphonenumber"

import {
  Box as Spacer,
  Button,
  CircularProgress,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
} from "@material-ui/core"

import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@material-ui/icons"
import CompanyPicker from "./CompanyPicker"
import SelectCountryList from "../layout/SelectCountryList"
import DoneIcon from "@material-ui/icons/Done"
import green from "@material-ui/core/colors/green"
import TruncateAsRequired from "../layout/TruncateAsRequired"
import CountryCodeMap from "../../Utils/countryDataList.json"
import { isEmailValid } from "../../Utils/helpers"
import { Label, Field } from "../../Utils/uiElements"

const MAX_TEXTFIELD_LENGTH = 255
const USER_API = `${process.env.REACT_APP_USER_URL}/idm`
const USER_EMAIL_SEARCH_API = process.env.REACT_APP_USER_URL

function isValidNumberForRegion(value, region) {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance()
    const _number = phoneUtil.parse(value, region)
    return phoneUtil.isValidNumberForRegion(_number, region)
  } catch (error) {
    console.error(error)
  }
}

function getCountryCode(region) {
  const phoneUtil = PhoneNumberUtil.getInstance()
  return phoneUtil.getCountryCodeForRegion(region)
}

function formatAsTyped(value, region) {
  const formatter = new AsYouTypeFormatter(region)
  const digits = value.split("")
  let output = ""
  let digit = digits.shift()
  while (digit) {
    output = formatter.inputDigit(digit)
    digit = digits.shift()
  }
  formatter.clear()
  return output
}

const checkDuplicateEmail = searchTerm =>
  axios
    .post(USER_EMAIL_SEARCH_API, {
      limit: 1,
      offset: 0,
      sortBy: { key: "emailAddress", value: "asc" },
      searchBy: searchTerm,
    })
    .catch(console.error)
    .then(res => res?.data?.records)
    .then(email =>
      email?.some(
        e => e.emailAddress.toLowerCase() === searchTerm.toLowerCase(),
      ),
    )

const reducer = (state, action) => {
  const { field, currentVal, errorDuplicateEmail } = action
  let errorMsg, newState
  switch (field) {
    case "email":
      errorMsg = !isEmailValid(currentVal) // : !/\w+@\w[\w.]*$/.test(value)!/.+@.+/.test(currentVal)
        ? "Please enter a valid email address"
        : currentVal.length > MAX_TEXTFIELD_LENGTH
        ? `Email address exceeds ${MAX_TEXTFIELD_LENGTH} characters`
        : errorDuplicateEmail
        ? "A user with this email address already exists"
        : false

      newState = {
        ...state,
        email: { currentVal, errorMsg },
      }
      break

    case "firstName":
      errorMsg = !currentVal
        ? "Please enter a first name"
        : currentVal.length > MAX_TEXTFIELD_LENGTH
        ? `Name exceeds ${MAX_TEXTFIELD_LENGTH} characters`
        : false
      newState = {
        ...state,
        firstName: { currentVal, errorMsg },
      }
      break

    case "lastName":
      errorMsg = !currentVal
        ? "Please enter a surname"
        : currentVal.length > MAX_TEXTFIELD_LENGTH
        ? `Name exceeds ${MAX_TEXTFIELD_LENGTH} characters`
        : false

      newState = {
        ...state,
        lastName: { currentVal, errorMsg },
      }
      break

    case "password":
      errorMsg = !currentVal
        ? "Please choose a password"
        : currentVal.length < 8
        ? "Password should be at least 8 characters long"
        : !/[0-9]/.test(currentVal)
        ? "Passwords must contain at least 1 number"
        : !/[A-Z]/.test(currentVal)
        ? "Passwords must contain at least 1 uppercase character"
        : !/[a-z]/.test(currentVal)
        ? "Passwords must contain at least 1 lowercase character"
        : currentVal.length > MAX_TEXTFIELD_LENGTH
        ? `Name exceeds ${MAX_TEXTFIELD_LENGTH} characters`
        : false

      newState = {
        ...state,
        password: { currentVal, errorMsg },
      }
      break

    case "pswdConfirmation":
      errorMsg = !currentVal
        ? "Please enter the password again"
        : currentVal !== state.password.currentVal
        ? "Passwords do not match"
        : false
      newState = {
        ...state,
        pswdConfirmation: { currentVal, errorMsg },
      }
      break

    case "phoneNumber":
      const region = state.region.currentVal
      let formatedCurrentVal = currentVal
      errorMsg =
        currentVal &&
        (!region
          ? "Please select a country (the country code will then be inserted)"
          : (currentVal && currentVal.length < 5) ||
            (currentVal && currentVal.length >= 18)
          ? "Not a valid phone number for the country selected"
          : !isValidNumberForRegion(currentVal, region)
          ? "Not a valid phone number for the country selected"
          : false)
      // remove all non numeric charaters for formatting
      const currentValCleaned = currentVal.replace(/\D/g, "")
      formatedCurrentVal = formatAsTyped(currentValCleaned, region)

      newState = {
        ...state,
        phoneNumber: {
          currentVal,
          errorMsg,
          formatedCurrentVal,
        },
      }
      break

    case "license":
      if (!currentVal) errorMsg = "This is required"
      return { ...state, license: { currentVal, errorMsg } }

    case "region":
      if (!currentVal.length === 2) errorMsg = "This is required"

      const countryFound = CountryCodeMap.find(
        it => it.value === currentVal,
      )?.label
      const countryName = countryFound ? countryFound : currentVal
      const countryCallingCode = currentVal
        ? "+" + getCountryCode(currentVal)
        : ""
      newState = {
        ...state,
        region: { currentVal, countryName, countryCallingCode, errorMsg },
      }
      break

    default:
      newState = { ...state, [field]: { currentVal } }
  }
  const anyRequiredFieldEmpty = fieldsNeedingValidation.some(
    fieldName => newState[fieldName]?.currentVal?.length === 0,
  )
  // Check if any field has `errorMsg`
  const submitDisabled =
    !Object.values(newState).reduce((acc, x) => acc && !x.errorMsg, true) ||
    anyRequiredFieldEmpty

  return { ...newState, submitDisabled }
}

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    margin: "auto",
    padding: spacing(3),
  },
  button: {
    marginLeft: spacing(2),
    minWidth: spacing(15),
  },
  status: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  section: {
    marginBottom: spacing(3),
  },
  doneIcon: {
    color: green[500],
    margin: "0 4px -4px 0",
  },
  successText: { color: green[500] },
  helperTextWordWrap: {
    wordBreak: "normal",
  },
  phoneNumberPrefix: {
    marginRight: "2px",
    marginTop: "2px",
  },
}))

const fieldsNeedingValidation = [
  "email",
  "firstName",
  "lastName",
  "password",
  "pswdConfirmation",
  "license",
]

const initialState = {
  submitDisabled: true,
  email: { currentVal: "" },
  password: { currentVal: "" },
  firstName: { currentVal: "" },
  lastName: { currentVal: "" },
  phoneNumber: { currentVal: "" },
  organisationId: { currentVal: "" },
  industry: { currentVal: "" },
  region: { currentVal: "", countryCallingCode: "", countryName: "" },
  jobTitle: { currentVal: "" },
  department: { currentVal: "" },
  pswdConfirmation: { currentVal: "" },
  license: { currentVal: "studio-app" },
}

export default forwardRef((props, ref) => {
  const styles = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [licenses, setLicenses] = useState(null)
  const [showCountryCode, setShowCountryCode] = useState(false)

  const emailInput = useRef(null)
  const firstNameInput = useRef(null)
  const lastNameInput = useRef(null)
  const jobTitleInput = useRef(null)
  const industryInput = useRef(null)
  const departmentInput = useRef(null)
  const phoneNumberInput = useRef(null)

  if (!licenses) {
    axios
      .get(`${process.env.REACT_APP_USER_URL}/entitlements/meta`)
      .then(
        x =>
          x.data?.data
            .filter(li => li.mapType === "CSP")
            .sort((a, b) => a.displayName.localeCompare(b.displayName)) || [],
      ) // Filtering entitlements of mapType: "CSP" only Refer to CSP-793

      .then(setLicenses)
    // // CSP-511
    // .then(licenses =>
    //   licenses.map(license => {
    //     if (license.entitlement === "studio-app")
    //       license.displayName = "NLG Studio"
    //
    //     return license
    //   }),
    // )
    //
    // .then(setLicenses)
  }

  // type reqStatus =
  //   | { status: IDLE | LOADING | SUCCESS }
  //   | { status: ERROR, errors: string[] }
  const [reqStatus, setReqstatus] = useState({ status: "IDLE" })
  const [noPassword, setNoPassword] = React.useState(true)
  const [checked, setChecked] = React.useState(false)

  function updatePasswordChecked() {
    // Inject dummy password to pass through validation check as null and empty string
    // cannot be passed through the validation and later change it to null when submitting
    const dummyPassword = !checked ? "Test123!" : ""
    const action1 = { field: "password", currentVal: dummyPassword }
    const action2 = { field: "pswdConfirmation", currentVal: dummyPassword }
    setNoPassword(!checked ? true : false)
    dispatch(action1)
    dispatch(action2)
  }
  useEffect(() => {
    updatePasswordChecked()
  }, [checked])

  const handleChecked = () => {
    setChecked(prev => !prev)
  }

  const customReset = () => {
    setTimeout(() => {
      const lastLicense = state.license.currentVal
      const lastOrganisationId = state.organisationId.currentVal
      emailInput.current.value = ""
      firstNameInput.current.value = ""
      lastNameInput.current.value = ""
      jobTitleInput.current.value = ""
      industryInput.current.value = ""
      departmentInput.current.value = ""
      phoneNumberInput.current.value = ""
      Object.entries(state)
        .map(([key, v]) => key)
        .forEach(field => {
          dispatch({ field, currentVal: "", errorMsg: false })
        })
      dispatch({ field: "license", currentVal: lastLicense })
      dispatch({ field: "organisationId", currentVal: lastOrganisationId })
      updatePasswordChecked()
    })
  }

  const submit = async _ => {
    if (noPassword) updatePasswordChecked()
    setReqstatus({ status: "LOADING" })
    let invalidFields = []
    fieldsNeedingValidation.forEach(field => {
      const { currentVal } = state[field]

      // Call the reducer to check for errors
      const st = reducer(state, { field, currentVal })

      // If the field has an `errorMsg`, it's invalid
      const invalid = !!st[field].errorMsg
      invalid && invalidFields.push(field)
    })

    // Check for duplicate email on submit
    const duplicate = await checkDuplicateEmail(state.email.currentVal)

    if (duplicate) {
      const action = { field: "email", currentVal: state.email.currentVal }
      action.errorDuplicateEmail = true
      dispatch(action)
      emailInput.current.focus()
      setReqstatus({ status: "IDLE" })
      return
    }

    if (noPassword) {
      state.password.currentVal = null
    }

    if (invalidFields.length > 0) {
      // Call the reducer for invalid fields so UI is updated with the errors
      invalidFields.forEach(field =>
        dispatch({ field, currentVal: state[field].currentVal }),
      )

      setReqstatus({
        status: "ERROR",
        msg: "Form contains errors. Please review the fields and try again.",
      })
      return
    }
    if (!state.organisationId.currentVal) {
      state.organisationId.currentVal = props.organisationId
    }

    // save country code with the phone number as the API (/user/idm) doesn't allow
    // a phone number without a plus prefixed country code
    const sPhoneNumber = state.phoneNumber.currentVal
    const sRegionCode = state.region.currentVal
    if (sPhoneNumber && sRegionCode) {
      const phoneUtil = PhoneNumberUtil.getInstance()
      const parsedNumber = phoneUtil.parse(sPhoneNumber, sRegionCode)
      const validNumberFormat = phoneUtil.isValidNumberForRegion(
        parsedNumber,
        sRegionCode,
      )
      if (validNumberFormat) {
        state.phoneNumber.currentVal = phoneUtil.format(
          parsedNumber,
          PhoneNumberFormat.E164,
        )
      }
    }

    // Remove `pswdConfirmation` because that's not valid API payload
    // and remove `license` because it actually needs to be `entitlements`
    const user = Object.fromEntries(
      Object.entries(state)
        .map(([key, v]) => [key, v.currentVal])
        .filter(([k]) => !["pswdConfirmation", "license"].includes(k)),
    )

    user.entitlements = [state.license.currentVal]

    const res = await axios.post(USER_API, user).catch(e => {
      const err = e.response?.data

      setReqstatus({
        status: "ERROR",
        errors: e.details || [err.message],
      })
    })

    if (res?.status === 200) {
      setReqstatus({ status: "SUCCESS" })
      customReset()
    }
  }

  // Generalised handler generator so fields don't need arrow functions
  const getHandlerFor = field => ev => {
    // Reset 'Form contains error' msg if any field is changed
    if (reqStatus.status === "ERROR") {
      setReqstatus({ status: "IDLE" })
    }
    // TODO: find better way to trigger dispatch
    // to enforce validation on phonenumber field after
    // choosing right country code
    if (field === "region" && state.phoneNumber.currentVal) {
      dispatch({
        field: "region",
        currentVal: ev.target ? ev.target.value : ev,
      })
      dispatch({
        field: "phoneNumber",
        currentVal: state.phoneNumber.formatedCurrentVal,
      })
      return
    }
    // fix for the cursor position on phone number
    if (field === "phoneNumber") {
      const currentVal = ev.target ? ev.target.value : ev
      const regionCurrentVal = state.region.currentVal
      dispatch({
        field,
        currentVal: ev.target ? ev.target.value : ev,
        eventTarget: ev.target,
      })
      const cursorStart = ev.target.selectionStart
      const cursorEnd = ev.target.selectionEnd
      if (cursorStart !== ev.target.value.length) {
        setCurPos(ev.target, cursorStart, cursorEnd)
      }
      // country code validation for empty state
      if (currentVal === "" && regionCurrentVal === "") {
        dispatch({
          field: "region",
          currentVal: state.region.currentVal,
        })
      }
      return
    }

    const currentVal = ev.target ? ev.target.value : ev

    dispatch({ field, currentVal })
  }

  function setCurPos(el, sPos, ePos) {
    setTimeout(() => {
      el.setSelectionRange(sPos, ePos)
    })
  }
  // focus and blur for phonenumber field to show and hide country code
  function getFocusHandler(evt) {
    setShowCountryCode(true)
  }
  function getBlurHandler(evt) {
    if (!state.phoneNumber.currentVal) {
      setShowCountryCode(false)
    }
  }

  return (
    <Paper className={styles.paper} innerRef={ref}>
      <Label label="Create User" variant="h5" />
      <Label
        label="
        Ensure the email address is correct to avoid sending the password to an
        unknown person."
        variant="body2"
      />
      <Spacer m={2} />
      <Label label="Account Information" />
      <Grid container spacing={3} className={state.section}>
        <Field
          label="Email"
          onChange={getHandlerFor("email")}
          field={state.email}
          type="email"
          required
          autoFocus
          full
          inputRef={emailInput}
        />
        <Field
          label="First name"
          onChange={getHandlerFor("firstName")}
          field={state.firstName}
          required
          inputRef={firstNameInput}
        />
        <Field
          label="Surname"
          onChange={getHandlerFor("lastName")}
          field={state.lastName}
          required
          inputRef={lastNameInput}
        />
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={checked}
                onChange={handleChecked}
              />
            }
            label="Specify a temporary password"
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={checked}>
            <Label
              label="If the user cannot receive a temporary password by email, you can specify one here and communicate it directly to the user."
              variant="body2"
            />
            <Grid container justify="space-between" spacing={3}>
              <Field
                item
                label="One time password"
                type="password"
                onChange={getHandlerFor("password")}
                field={state.password}
                required
              />

              <Field
                item
                type="password"
                label="Confirm password"
                onChange={getHandlerFor("pswdConfirmation")}
                field={state.pswdConfirmation}
                required
              />
            </Grid>
            <Spacer m={2} />
          </Collapse>
        </Grid>
      </Grid>

      <Label label="Assign to Company (optional)" />
      <CompanyPicker
        initialValue={props.organisationName}
        setValue={getHandlerFor("organisationId")}
      />

      <Spacer m={5} />
      <Label label="Assign a license" />
      {licenses && (
        <Field
          required
          select
          full
          field={state.license}
          id="select-license"
          value={state.license.currentVal}
          onChange={getHandlerFor("license")}
        >
          {licenses.map(l => (
            <MenuItem key={l.entitlement} value={l.entitlement}>
              {l.displayName}
            </MenuItem>
          ))}
        </Field>
      )}

      <Spacer m={5} />
      <Label label="Additional Fields (optional)" />
      <Grid container spacing={2}>
        <Field
          field={state.jobTitle}
          onChange={getHandlerFor("jobTitle")}
          label="Job title"
          inputRef={jobTitleInput}
        />
        <Field
          field={state.industry}
          onChange={getHandlerFor("industry")}
          label="Industry"
          inputRef={industryInput}
        />
        <Field
          field={state.department}
          onChange={getHandlerFor("department")}
          label="Department"
          inputRef={departmentInput}
        />
        <Grid item xs={6}>
          <SelectCountryList
            value={state.region.currentVal}
            onChange={getHandlerFor("region")}
          />
        </Grid>
        <Field
          field={state.phoneNumber}
          value={state.phoneNumber.formatedCurrentVal}
          onChange={getHandlerFor("phoneNumber")}
          onFocus={getFocusHandler}
          onBlur={getBlurHandler}
          label="Phone number"
          inputRef={phoneNumberInput}
          InputProps={
            state.region.countryCallingCode && showCountryCode
              ? {
                  startAdornment: (
                    <InputAdornment
                      className={styles.phoneNumberPrefix}
                      position="start"
                      margin="dense"
                    >
                      <TruncateAsRequired
                        title={state.region.countryName}
                        maxWidth="30vw"
                        placement="top-start"
                      >
                        {state.region.countryCallingCode}
                      </TruncateAsRequired>
                    </InputAdornment>
                  ),
                }
              : false
          }
        />
      </Grid>

      <Grid item className={styles.status}>
        {reqStatus.status === "LOADING" ? (
          <Typography color="primary" variant="subtitle2">
            Creating User...
          </Typography>
        ) : reqStatus.status === "ERROR" ? (
          <>
            <Typography color="error" variant="body2">
              Couldn't create a user with these details, because:
            </Typography>
            {reqStatus.errors.map(msg => (
              <Typography key={msg} color="error" variant="subtitle2">
                - {msg}
              </Typography>
            ))}
          </>
        ) : reqStatus.status === "SUCCESS" ? (
          <Typography className={styles.successText} variant="subtitle2">
            <DoneIcon fontSize="small" className={styles.doneIcon} />
            User successfully created
          </Typography>
        ) : (
          <Spacer m={5} />
        )}
      </Grid>
      <Grid container justify="flex-end">
        <Grid item>
          <Button
            variant="outlined"
            className={styles.button}
            onClick={props.dismiss}
          >
            Close
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={submit}
            className={styles.button}
            disabled={
              ["LOADING", "ERROR"].includes(reqStatus.status) ||
              state.submitDisabled
            }
          >
            {reqStatus.status === "LOADING" ? (
              <CircularProgress size={25} />
            ) : (
              "Create User"
            )}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
})
