import React from "react"
import PropTypes from "prop-types"

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core"

const OrganisationListTableHead = ({ activeKey, direction, onSort }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: "35%" }}>
          <TableSortLabel
            active={activeKey === "displayName"}
            direction={direction}
            onClick={() => onSort("displayName")}
          >
            Company name
          </TableSortLabel>
        </TableCell>
        <TableCell style={{ width: "20%" }}>
          <TableSortLabel
            active={activeKey === "partnerName"}
            direction={direction}
            onClick={() => onSort("partnerName")}
          >
            Partner
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={activeKey === "userCount"}
            direction={activeKey === "userCount" ? direction : "desc"}
            onClick={() => onSort("userCount")}
          >
            Total users
          </TableSortLabel>
        </TableCell>
        <TableCell align="left">
          <TableSortLabel
            active={activeKey === "accountStatus"}
            direction={direction}
            onClick={() => onSort("accountStatus")}
          >
            Status
          </TableSortLabel>
        </TableCell>
        <TableCell align="right">
          <TableSortLabel
            align="right"
            active={activeKey === "createdDate"}
            direction={activeKey === "createdDate" ? direction : "desc"}
            onClick={() => onSort("createdDate")}
          >
            Join date
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

OrganisationListTableHead.propTypes = {
  activeKey: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
}

export default OrganisationListTableHead
