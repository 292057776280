import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { withContext } from "../../../../Utils/context"
import { maxCharLength } from "../../../../Utils/helpers"
import axios from "axios"
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Fab,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { useHistory } from "react-router-dom"
import { excludedEmailDomains } from "../../../../Utils/helpers"

const ORG_API = process.env.REACT_APP_ORG_URL

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1),
    fontSize: ".8em",
  },
  progressWrap: {
    padding: theme.spacing(6),
  },
  progress: {
    position: "absolute",
    left: "50%",
  },
  addIcon: {
    fontSize: "1em",
  },
}))

const checkName = searchTerm =>
  axios
    .post(ORG_API, {
      limit: 1,
      offset: 0,
      sortBy: { key: "displayName", value: "asc" },
      searchBy: searchTerm,
    })
    .catch(console.error)
    .then(res => res?.data?.records)
    .then(companies =>
      companies?.some(
        c => c.displayName.toLowerCase() === searchTerm.toLowerCase(),
      ),
    )

const useDebounce = (searchTerm, delay = 1000) => {
  const [debouncedVal, setDebouncedVal] = React.useState(searchTerm)

  React.useEffect(() => {
    const timerRef = setTimeout(() => setDebouncedVal(searchTerm), delay)

    return () => clearTimeout(timerRef)
  }, [searchTerm, delay])

  return debouncedVal
}

function CreationModal() {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [modalOrgID, setModalOrgID] = React.useState("")
  const [emailDialog, setEmailDialog] = React.useState(false)
  const [displayName, setDisplayName] = React.useState("")
  const [emailDomain, setEmailDomain] = React.useState("")
  const [emailDomainError, setEmailDomainError] = React.useState("")
  const [emailDialogErrorMsg, setEmailDialogErrorMsg] = React.useState("")
  const [accountStatus, setAccountStatus] = React.useState("ACTIVE")
  const [loading, setLoading] = React.useState(false)
  const [checkingDuplicate, setCheckingDuplicate] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [displayNameMinLength, setDisplayNameMinLength] = React.useState(false)
  const [displayNameMaxLength, setDisplayNameMaxLength] = React.useState(false)
  const [displayNameMatch, setDisplayNameMatch] = React.useState(false)
  const domainRegex =
    /^(([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9])\.){1,}([A-Za-z]{2,6})$/

  const debouncedDisplayName = useDebounce(displayName, 500)

  const toggleDialog = () => setOpen(!open)
  const emailDialogToggle = () => {
    setEmailDialog(!emailDialog)
    history.push(`/company/${modalOrgID}/domains`)
  }

  React.useEffect(() => {
    const updateDuplicateStatus = () => {
      if (!debouncedDisplayName) {
        setCheckingDuplicate(false)
        return
      }

      // reset all error msg state
      setDisplayNameMinLength(false)
      setDisplayNameMaxLength(false)
      setDisplayNameMatch(false)

      if (debouncedDisplayName.length > maxCharLength) {
        setDisplayNameMaxLength(true)
        return
      }

      if (debouncedDisplayName.length < 2) {
        setDisplayNameMinLength(true)
        return
      }

      checkName(debouncedDisplayName)
        .then(setDisplayNameMatch)
        .then(_ => setCheckingDuplicate(false))
    }

    updateDuplicateStatus()
  }, [debouncedDisplayName])

  const handleNameChange = ev => {
    const val = ev.target.value
    setCheckingDuplicate(true)
    setDisplayName(val)
  }

  const handleEmailDomainChange = event => {
    const email = event.target.value.trim().toLowerCase()
    setEmailDomain(email)
    if (excludedEmailDomains.includes(email)) {
      setEmailDomainError("This email domain is not allowed.")
    } else if (!domainRegex.test(email)) {
      setEmailDomainError("Please enter a valid email domain.")
    } else {
      setEmailDomainError("")
    }
  }

  const createOrgEmailDomain = (orgId, emailDomains) => {
    const API = `${process.env.REACT_APP_ORG_URL}/${orgId}/emaildomains`
    return axios
      .patch(API, { emailDomains })
      .then(response => {
        return { statusCode: 200, orgId }
      })
      .catch(error => {
        return { statusCode: 400, orgId, error }
      })
  }

  const handleCreate = () => {
    setLoading(true)
    setError(false)
    axios
      .post(`${ORG_API}/create`, {
        displayName: debouncedDisplayName.trim(),
        accountStatus,
      })
      .then(res => {
        if (res.status >= 200 && res.status <= 201 && !emailDomain) {
          setOpen(false)
          history.push(`/company/${res.data.organisationId}`)
          return
        }
        return createOrgEmailDomain(res.data.organisationId, [emailDomain])
      })
      .then(data => {
        const { statusCode, orgId, error } = data
        if (statusCode === 400) {
          const err = error?.response?.data
          let customMessage = "There was an error adding the email domain. "
          customMessage =
            err.code === "DomainInUseException"
              ? customMessage + err.details
              : customMessage
          setEmailDialogErrorMsg(customMessage)
          setModalOrgID(orgId)
          setOpen(false)
          setEmailDialog(true)
        } else if (statusCode === 200) {
          history.push(`/company/${orgId}`)
        }
      })
      .catch(error => {
        setLoading(false)
        setError(true)
        console.error(error)
      })
  }

  const createDisabled =
    displayNameMatch ||
    displayNameMinLength ||
    displayNameMaxLength ||
    loading ||
    checkingDuplicate ||
    !displayName

  const displayNameErrorMsg = displayNameMatch
    ? "A company with this name already exists."
    : displayNameMinLength
    ? "Company name cannot have less than 2 characters."
    : displayNameMaxLength
    ? `Company name cannot have more than ${maxCharLength} characters.`
    : false

  return (
    <React.Fragment>
      <Dialog
        open={emailDialog}
        aria-labelledby="confirm-removal-title"
        aria-describedby="confirm-removal"
      >
        <DialogTitle id="confirm-removal-title">Email Domain Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-removal">
            <Typography variant="body1">
              Company creation was successful. {emailDialogErrorMsg}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={emailDialogToggle}>ok</Button>
        </DialogActions>
      </Dialog>
      <Fab
        variant="extended"
        color="primary"
        size="small"
        className={classes.icon}
        onClick={toggleDialog}
      >
        <AddIcon fontSize="small" className={classes.addIcon} />
        Create Company
      </Fab>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Create Company</DialogTitle>
        {loading ? (
          <div className={classes.progressWrap}>
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <React.Fragment>
            {error ? (
              <Typography variant="body1">
                Sorry an error occurred creating the company
              </Typography>
            ) : (
              <DialogContent>
                <DialogContentText
                  id="form-dialog-description"
                  style={{ wordBreak: "unset" }}
                >
                  To create a company, enter the name and a company email
                  domain.
                </DialogContentText>
                <TextField
                  id="displayName"
                  label="Company name"
                  error={
                    !!displayNameMatch ||
                    !!displayNameMinLength ||
                    !!displayNameMaxLength
                  }
                  helperText={displayNameErrorMsg}
                  value={displayName}
                  name="displayName"
                  onChange={handleNameChange}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                <Box m={1} />
                <TextField
                  id="emailDomain"
                  label="Email Domain"
                  value={emailDomain}
                  name="emailDomain"
                  onChange={handleEmailDomainChange}
                  error={!!emailDomainError}
                  helperText={emailDomainError}
                  required
                  variant="outlined"
                />
                <Box m={0.5} />

                <Typography variant="body2">
                  (more can be added in company details)
                </Typography>
              </DialogContent>
            )}
          </React.Fragment>
        )}
        <DialogActions>
          <Button onClick={toggleDialog} size="small" disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleCreate}
            variant="contained"
            color="primary"
            size="small"
            disabled={createDisabled || !!emailDomainError}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withContext(CreationModal)
