import React, { useState, useEffect } from "react"
import clsx from "clsx"
import axios from "axios"
import format from "date-fns/format"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core"
import {
  Refresh as RefreshIcon,
  Add as AddIcon,
  MailOutline as MailIcon,
  VpnKey as GenericLicenseIcon,
} from "@material-ui/icons"
import UserSelect from "./UserSelect"
import Searchbar from "../../../../layout/Searchbar"
import Pagination from "../../../../layout/Pagination"
import { TruncateAsRequired } from "../../../../layout/TruncateAsRequired"
import { withContext } from "../../../../../Utils/context"

const studioIconPath = require("../../../../assets/icons-studio-square-a.svg")
const excelPluginIconPath = require("../../../../assets/icons-excel-square-a.svg")
const exchangeAppIconPath = require("../../../../assets/arria-exchange.svg")
const biIconPath = require("../../../../assets/icons-bi-square-a.svg")
const biContributorIconPath = require("../../../../assets/icons-bi-contributor-square-a.png")

const ICONS = {
  "studio-app": studioIconPath,
  "excel-plugin": excelPluginIconPath,
  "excel-standard": excelPluginIconPath,
  "excel-finance-essentials": excelPluginIconPath,
  "excel-enterprise-trial": excelPluginIconPath,
  "excel-premium": excelPluginIconPath,
  "exchange-app": exchangeAppIconPath,
  "bi-creator-trial": biIconPath,
  "bi-creator-1-basic": biIconPath,
  "bi-creator-2-intermediate": biIconPath,
  "bi-creator-3-premium": biIconPath,
  "bi-contributor-1-basic": biContributorIconPath,
  "docs-app":
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAdElEQVRIie2SSw6AIAxEB+PpPJn39CC6RULsTIsEEl7Cht9LpwU6cwC4ANziovF8LgnkBxZby888AjaikxWWESl9oCQRASWJCkxJC8FLkiqCfD8ysgkYYEzD7OL9MtKcapzDVSA3vXsFXxlb56sHPuYX/M4DQ6dpfl/mnHIAAAAASUVORK5CYII=",
}

const NoData = () => (
  <span style={{ color: "grey", fontStyle: "italic" }}>Not Available</span>
)

const NoneAssinged = () => (
  <span style={{ color: "grey", fontStyle: "italic" }}>None assigned</span>
)

const getEntitlementsMeta = () =>
  axios
    .get(`${process.env.REACT_APP_USER_URL}/entitlements/meta`)
    .then(x => x.data?.data || [])

const Cell = ({ children, ...rest }) => (
  <TableCell {...rest}>
    {!children ||
    (typeof children === "string" && children.trim().length === 0) ? (
      <NoData />
    ) : (
      <TruncateAsRequired>{children}</TruncateAsRequired>
    )}
  </TableCell>
)

const EmptyContainer = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    minHeight={200}
  >
    {children}
  </Box>
)

const USER_API = process.env.REACT_APP_USER_URL
const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  flex: {
    flex: 1,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  mailIcon: {
    fontSize: "16px",
    verticalAlign: "middle",
    marginRight: "4px",
    color: "rgba(0, 0, 0, 0.38)",
  },
  chip: {
    fontSize: "8pt",
    fontWeight: 500,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  disabled: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
  emptyContainer: {
    minHeight: 200,
  },
}))

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

function Users({ organisationId, organisationName, createSuccess }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [searchBy, setSearchBy] = useState("")
  const [sortBy, setSortBy] = useState({
    key: "displayName",
    value: "asc",
  })
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  })
  const [userSelectOpen, setUserSelectOpen] = useState(false)
  const [entitlementsMeta, setEntitlementsMeta] = useState(null)

  const reset = () => {
    setLoading(false)
    setError(true)
  }

  useEffect(() => {
    if (!entitlementsMeta) getEntitlementsMeta().then(setEntitlementsMeta)

    getUsers()

    async function getUsers() {
      setError(false)
      setLoading(true)

      const filterBy = [{ key: "organisationId", value: [organisationId] }]
      let data = {
        filterBy,
        sortBy,
        limit: pagination.limit,
        offset: pagination.offset,
      }

      if (searchBy !== "") data.searchBy = searchBy

      const res = await axios.post(USER_API, data).catch(reset)
      const users = res.data?.records || []

      if (!users || users.length === 0) {
        setLoading(false)
        setData(users)
        return
      }

      const userIds = users.map(u => u.id)
      axios
        .post(`${REPORTING_API}/activity/user`, { userIds })
        .then(res => {
          const activities = res.data.results
          const usersWithUpdatedTimestamps = users.map(user => {
            const userActivity = activities.find(a => a.userId === user.id)
            if (!userActivity) {
              return user
            }

            user.lastLoginDate = userActivity.lastLoginDate
            user.lastUsageDate = userActivity.lastUsageDate
            return user
          })
          setLoading(false)
          setData(usersWithUpdatedTimestamps)
        })
        .catch(reset)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, pagination, getEntitlementsMeta])

  const toggleSelectOpen = () => setUserSelectOpen(!userSelectOpen)

  const handleSearchChange = value => {
    setSearchBy(value)
    setPagination({
      limit: pagination.limit,
      offset: 0,
    })
  }

  const handleSort = value => {
    setPagination({ limit: pagination.limit, offset: 0 })

    if (sortBy.key === value) {
      setSortBy({
        key: value,
        value: sortBy.value === "asc" ? "desc" : "asc",
      })
    } else {
      setSortBy({
        key: value,
        value: "asc",
      })
    }
  }

  const handleChangePage = (_event, newPage) => {
    setPagination({
      limit: pagination.limit,
      offset: newPage * pagination.limit,
    })
  }

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    if (!error && !loading) {
      setPagination({
        limit: value,
        offset: 0,
      })
    }
  }

  const handleReload = () =>
    setPagination({
      limit: pagination.limit,
      offset: pagination.offset,
    })

  const handleUserSelect = userId => {
    setUserSelectOpen(false)
    setLoading(true)
    axios
      .patch(`${USER_API}/${userId}`, { organisationId })
      .catch(reset)
      .then(_ => {
        setLoading(false)
        handleReload()
        createSuccess("User successfully added to company")
      })
  }

  const Entitlements = ({ entitlements }) =>
    entitlements?.split(",").map((en, i) => {
      const entitlement = entitlementsMeta.find(x => x.entitlement === en)
      return (
        <Tooltip title={entitlement.displayName} key={i}>
          {ICONS[en] ? (
            <img
              style={{ width: "24px", marginRight: "10px" }}
              src={ICONS[en]}
              alt={entitlement.displayName}
            />
          ) : (
            <GenericLicenseIcon />
          )}
        </Tooltip>
      )
    }) ?? <NoneAssinged />
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <div className={classes.flex}>
          <Searchbar
            placeholder="Search by name, email"
            disabled={false}
            onChange={handleSearchChange}
          />
        </div>
        <Tooltip title="Add user" placement="left">
          <IconButton
            aria-label="Add user"
            size="small"
            onClick={toggleSelectOpen}
            className={classes.icon}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Reload" placement="left">
          <span>
            <IconButton
              aria-label="Reload"
              size="small"
              onClick={handleReload}
              disabled={loading}
            >
              <RefreshIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Toolbar>
      <Divider />
      {loading ? (
        <EmptyContainer>
          <CircularProgress />
        </EmptyContainer>
      ) : error ? (
        <EmptyContainer>
          <Typography variant="body1">
            Sorry an error occurred fetching users
          </Typography>
        </EmptyContainer>
      ) : data.length === 0 ? (
        <EmptyContainer>
          <Typography variant="body1">
            {searchBy === ""
              ? "No users associated"
              : `No users found with '${searchBy}'`}
          </Typography>

          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={toggleSelectOpen}
            style={{ marginTop: "8px" }}
          >
            Add user
          </Button>
        </EmptyContainer>
      ) : (
        <>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Cell style={{ width: "20%" }}>
                  <TableSortLabel
                    active={sortBy.key === "displayName"}
                    direction={sortBy.value}
                    onClick={() => handleSort("displayName")}
                  >
                    User name
                  </TableSortLabel>
                </Cell>
                <Cell style={{ width: "20%" }}>
                  <TableSortLabel
                    active={sortBy.key === "emailAddress"}
                    direction={sortBy.value}
                    onClick={() => handleSort("emailAddress")}
                  >
                    Email
                  </TableSortLabel>
                </Cell>
                <Cell style={{ width: "15%" }}>License</Cell>
                <Cell align="center">
                  <TableSortLabel
                    active={sortBy.key === "accountStatus"}
                    direction={sortBy.value}
                    onClick={() => handleSort("accountStatus")}
                  >
                    Status
                  </TableSortLabel>
                </Cell>
                <Cell style={{ width: "10%" }}>Last Sign In (UTC)</Cell>
                <Cell style={{ width: "10%" }}>Last Usage (UTC hourly)</Cell>
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                {data.slice(0, pagination.limit).map(user => (
                  <TableRow key={user.id} className="slowfadein">
                    <Cell style={{ fontSize: 14 }}>
                      <Link to={"/user/" + user.id}>{user.displayName}</Link>
                    </Cell>
                    <Cell>
                      <a
                        href={"mailto:" + user.emailAddress}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MailIcon className={classes.mailIcon} />
                        {user.emailAddress}
                      </a>
                    </Cell>
                    <Cell>
                      <Entitlements entitlements={user.entitlements} />
                    </Cell>
                    <Cell align="center">
                      <Chip
                        label={user.accountStatus}
                        size="small"
                        className={clsx(classes.chip, {
                          [classes.success]: user.accountStatus === "ACTIVE",
                          [classes.warning]: user.accountStatus === "SUSPENDED",
                          [classes.disabled]: user.accountStatus === "DISABLED",
                        })}
                      />
                    </Cell>
                    <Cell>
                      {user.lastLoginDate
                        ? format(
                            nowUTC(user.lastLoginDate),
                            "d MMM yyyy, h:mm a",
                          )
                        : " "}
                    </Cell>
                    <Cell>
                      {user.lastUsageDate
                        ? format(nowUTC(user.lastUsageDate), "d MMM yyyy, h a")
                        : " "}
                    </Cell>
                  </TableRow>
                ))}
                {data.length < pagination.limit && (
                  <TableRow
                    style={{ height: 33 * (pagination.limit - data.length) }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </React.Fragment>
            </TableBody>
          </Table>
          <Pagination
            data={data}
            offset={pagination.offset}
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={[10, 20, 30]}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
      <UserSelect
        organisationName={organisationName}
        organisationId={organisationId}
        open={userSelectOpen}
        onToggleOpen={toggleSelectOpen}
        onSave={handleUserSelect}
        refreshUsers={handleReload}
      />
    </React.Fragment>
  )
}

export default withContext(Users)
