import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { AuthContext } from "../../../../Utils/context"
import axios from "axios"
import { Link, useParams } from "react-router-dom"

import { Grid, Typography, Paper, Chip } from "@material-ui/core"

import TruncateAsRequired from "../../../layout/TruncateAsRequired"

import UpdateForm from "./UpdateForm"
import Identities from "./Identities"
import { Tabs } from "./Tabs"

const USER_API = process.env.REACT_APP_USER_URL
const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const UserDetailPage = () => {
  const classes = useStyles()
  const { createError } = useContext(AuthContext)

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [user, setUser] = useState(null)
  const [primary, setPrimary] = useState(null)
  const [federated, setFederated] = useState(null)
  const [activity, setActivity] = useState(null)

  const { userId } = useParams()

  const verifyPrimary = () => {
    const url = process.env.REACT_APP_USER_URL + `/verify`
    const payload = { email: user.emailAddress }

    axios
      .patch(url, payload)
      .then(res => {
        if (!res) throw new Error("Verification response was null.")

        // Trigger a rerender
        setPrimary({ ...primary, emailVerified: "true" })
      })
      .catch(error => {
        console.error("ERROR VERIFYING: ", error)

        const err = error?.response?.data
        let customMessage = "There was an issue verifying the user"

        customMessage = !err?.code
          ? customMessage
          : err.code === "UserNotFoundException"
          ? "This user does not exist"
          : err.code === "NotAuthorizedException" &&
            err.message?.endsWith("status is CONFIRMED")
          ? "User was already verified."
          : customMessage

        createError({ customMessage })
      })
  }

  useEffect(() => {
    axios
      .get(`${USER_API}/${userId}`)
      .then(userResponse => {
        const user = userResponse?.data?.record
        if (!user?.userId) return
        user.id = user.userId
        setUser(user)
        const identity = user.identity
        if (identity.identityProvider === "EXTERNAL_PROVIDER") {
          setFederated(identity)
        } else {
          setPrimary(identity)
        }

        // Assumption: If it's not BI, it must be Primary

        // Get activity for primary identity
        const payload = { userIds: [userId] }
        axios
          .post(`${REPORTING_API}/activity/user`, payload)
          .catch(() => {
            const errorMsg = "Sorry an error occurred loading the user activity"
            setErrorMessage(errorMsg)
            setError(true)
          })
          .then(activityResponse => {
            const act = activityResponse?.data?.results?.[0]
            act && setActivity(act)
          })
      })
      .catch(err => {
        console.log(err, "error")
        const errorMsg = "Sorry an error occurred loading the user"
        setErrorMessage(errorMsg)
        setError(true)
      })
  }, [userId])

  // After user form has updated, this needs to be updated for the displayName.
  // UpdateForm props are not rerendered.
  const handleUserUpdate = newUser =>
    setUser({
      ...newUser,
      displayName: newUser.firstName + " " + newUser.lastName,
    })

  if (error) {
    return (
      <Paper className={classes.errorPaper}>
        <Typography variant="h5" align="center">
          {errorMessage}
        </Typography>
      </Paper>
    )
  }

  if (!user) {
    return <Typography>Fetching user...</Typography>
  }

  document.title = `${user.displayName} — CSP`

  const Header = () => (
    <Grid container alignItems="center" justify="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <TruncateAsRequired
              component="h4"
              variant="h6"
              maxWidth="80vw"
              title={user.displayName}
              className={classes.title}
            >
              {user.displayName}
            </TruncateAsRequired>
          </Grid>
          <Grid item>
            <Chip
              label={user.accountStatus}
              size="small"
              className={clsx(classes.chip, {
                [classes.success]: user.accountStatus === "ACTIVE",
                [classes.warning]: user.accountStatus === "SUSPENDED",
                [classes.danger]: user.accountStatus === "DISABLED",
              })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {user.organisationId && (
          <Link to={`/company/${user.organisationId}`}>
            <TruncateAsRequired
              component="h3"
              variant="h6"
              title={user.organisationName}
            >
              {user.organisationName}
            </TruncateAsRequired>
          </Link>
        )}
      </Grid>
    </Grid>
  )

  return (
    <>
      <Header />
      <Grid container className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Typography variant="h6" className={classes.subtitle}>
              User Details
            </Typography>
            <UpdateForm user={user} onUserUpdate={handleUserUpdate} />
          </Grid>

          <Grid item xs={12} md={7}>
            <Typography variant="h6" className={classes.subtitle}>
              {federated ? "Identity" : "Identities"}
            </Typography>
            <Identities
              user={user}
              primary={primary}
              federated={federated}
              activity={activity}
              verifyPrimary={verifyPrimary}
              onUserUpdate={handleUserUpdate}
            />
          </Grid>
          <Tabs user={user} />
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  errorPaper: {
    padding: theme.spacing(8),
    width: "100%",
    fontWeight: 300,
  },
  title: {
    fontWeight: 500,
    color: "#4a4a4a",
    fontSize: "24px",
  },
  chip: {
    marginLeft: theme.spacing(1),
    fontSize: "8pt",
    color: "#000",
    fontWeight: 500,
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  danger: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
  subtitle: {
    fontWeight: 300,
    color: "#4a4a4a",
    fontSize: "16px",
    marginBottom: theme.spacing(1),
  },
  paddingBottom: {
    padding: 0,
    paddingBottom: 40,
  },
  content: { marginTop: 18 },
}))

export default UserDetailPage
