import React from "react"

import { Grid, TextField, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    margin: "auto",
    padding: spacing(3),
  },
  button: {
    marginLeft: spacing(2),
    minWidth: spacing(15),
  },
  status: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  section: {
    marginBottom: spacing(3),
  },
  helperTextWordWrap: {
    wordBreak: "normal",
  },
  phoneNumberPrefix: {
    marginRight: "2px",
    marginTop: "2px",
  },
}))

export const Label = ({ label, ...rest }) => (
  <Typography
    variant="subtitle2"
    component="h6"
    style={{ marginBottom: 10 }}
    {...rest}
  >
    {label}
  </Typography>
)

export const Field = ({ field, full, label, ...rest }) => {
  const styles = useStyles()
  return (
    <Grid item xs={full ? 12 : 6}>
      <TextField
        FormHelperTextProps={{
          className: styles.helperTextWordWrap,
        }}
        error={!!field.errorMsg}
        helperText={field.errorMsg}
        autoComplete="none"
        variant="outlined"
        size="small"
        label={label}
        fullWidth
        {...rest}
      />
    </Grid>
  )
}
