import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber"
import CountryCodeMap from "../../../../Utils/countryDataList.json"
import format from "date-fns/format"
import grey from "@material-ui/core/colors/grey"
import classNames from "classnames"

import {
  Grid,
  Paper,
  IconButton,
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core"

import {
  Done as DoneIcon,
  FileCopyOutlined as CopyIcon,
  Phone as PhoneIcon,
} from "@material-ui/icons"
import SendIcon from "@material-ui/icons/Send"
import axios from "axios"
import { nowUTC } from "../../../../Utils/helpers"
import { withContext } from "../../../../Utils/context"
import NoData from "../../../layout/NoData"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"

const USER_API = process.env.REACT_APP_USER_URL

const Empty = React.Fragment

const UserProfile = ({ primary }) => {
  if (!primary || !primary.profile) return <NoData />
  const { profile, phoneNumber } = primary
  const { companyName, region, jobTitle, industry, department } = profile || {}

  // check for matching country from region code
  const countryFound = CountryCodeMap.find(it => it.value === region)?.label
  const country = countryFound ? countryFound : region

  const Field = ({ label, style, value = "" }) => (
    <Grid item xs={6} style={{ ...s.field, ...style }}>
      <Typography style={s.label}>{label}</Typography>
      {value ? (
        <Box display="block">
          <TruncateAsRequired title={value} style={s.lightGrey}>
            {value}
          </TruncateAsRequired>
        </Box>
      ) : (
        <NoData />
      )}
    </Grid>
  )

  return (
    <Grid container style={s.profile}>
      <Grid container>
        <Typography component="span" variant="subtitle2">
          User Profile
        </Typography>
      </Grid>
      <Field label="Company" value={companyName} />
      <Field label="Country" value={country} style={s.rowPad} />
      <Field label="Job title" value={jobTitle} />
      <Field label="Industry" value={industry} style={s.rowPad} />
      <Field label="Department" value={department} />

      <Grid item xs={6} style={{ ...s.rowPad, ...s.rowPhone }}>
        <Typography style={s.label}>Phone Number</Typography>
        <Phone number={phoneNumber} region={region} />
      </Grid>
    </Grid>
  )
}

const UsageDate = ({ date, fmt }) => {
  if (!date) return <NoData />

  const usageDate = nowUTC(date)

  return (
    <Typography variant="subtitle2" style={s.value}>
      {format(usageDate, fmt)}
    </Typography>
  )
}

const Phone = ({ number, region }) => {
  if (!number) return <NoData />
  let callingPhoneNumber = ""
  let formatedPhoneNumber = ""

  // remove + sign from the phone number if there is any
  const phoneNumber = number.replace(/^[+]/, "")

  // if region is more than 2 character length then check
  // if it has a matching country code
  const regionCode =
    region.length === 2
      ? region
      : CountryCodeMap.find(it => it.value === region)?.value

  if (regionCode) {
    const phoneUtil = PhoneNumberUtil.getInstance()
    const parsedNumber = phoneUtil.parse(phoneNumber, regionCode)
    const validNumberFormat = phoneUtil.isValidNumberForRegion(
      parsedNumber,
      regionCode,
    )
    // if phone number is valid for the region then only format
    if (validNumberFormat) {
      formatedPhoneNumber = phoneUtil.format(
        parsedNumber,
        PhoneNumberFormat.INTERNATIONAL,
      )
      callingPhoneNumber = phoneUtil.format(
        parsedNumber,
        PhoneNumberFormat.E164,
      )
    }
  }

  return (
    <a
      href={callingPhoneNumber ? "tel:" + callingPhoneNumber : "tel:" + number}
      style={{ cursor: "pointer" }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography color="primary">
            {formatedPhoneNumber ? formatedPhoneNumber : number}
          </Typography>
        </Grid>
        <Grid item>
          <PhoneIcon color="primary" fontSize="small" />
        </Grid>
      </Grid>
    </a>
  )
}

const EmailAddresses = withContext(
  ({ primary, federated, copyToClipboard, userPoolId, verifyPrimary }) => {
    const emailVerified = x => x?.emailVerified?.toLowerCase() === "true"
    return (
      <Grid item xs={federated ? 12 : 6}>
        {primary && (
          <Grid item xs={12} style={s.row}>
            <Grid item xs={3} md={3}>
              <Typography style={s.label}>Primary Email:</Typography>
            </Grid>
            <Grid item xs={9} md={9}>
              <Typography component="span" variant="subtitle2" style={s.value}>
                {emailVerified(primary) ? (
                  <>
                    Verified <DoneIcon style={s.doneIcon} />
                  </>
                ) : (
                  <Button
                    style={s.verifyBtn}
                    variant="outlined"
                    onClick={verifyPrimary}
                  >
                    Verify
                  </Button>
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        {federated && (
          <>
            <Grid item xs={12} style={{ ...s.row, ...s.userIdContainer }}>
              <Typography style={{ marginTop: "25px", fontSize: "14px" }}>
                This user has a federated identity which is managed directly by
                their organization.
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ ...s.row, ...s.userIdContainer }}>
              <Typography style={s.label}>User Pool ID:</Typography>
              <IconButton
                aria-label="copy"
                size="small"
                title="Copy to Clipboard"
                onClick={() => copyToClipboard(userPoolId)}
              >
                <CopyIcon style={{ fontSize: 15, marginLeft: "-25px" }} />
              </IconButton>
              <pre
                id="user-id-raw"
                style={{
                  marginLeft: "-5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userPoolId}
              </pre>
            </Grid>
          </>
        )}

        {primary && (
          <Grid item xs={12} style={{ ...s.row, ...s.userIdContainer }}>
            <Grid item xs={3} md={3}>
              <Typography style={s.label}>Primary ID:</Typography>
            </Grid>
            <Grid item xs={9} md={9}>
              <span style={{ position: "relative" }}>
                <IconButton
                  aria-label="copy"
                  size="small"
                  title="Copy to Clipboard"
                  onClick={() => copyToClipboard(primary.identityId)}
                  style={{ position: "absolute", top: "13px", left: "-4px" }}
                >
                  <CopyIcon style={{ fontSize: 15 }} />
                </IconButton>
                <pre
                  id="user-id-raw"
                  style={{
                    marginLeft: "20px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {primary.identityId}
                </pre>
              </span>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  },
)

const Activity = ({ activity }) => {
  if (!activity) return <Empty />

  const { lastLoginDate = "", lastUsageDate = "" } = activity
  const fmt = {
    login: "d MMM yyyy, h:mm a",
    usage: "d MMM yyyy, h a",
  }
  const labelStyle = { ...s.label }

  return (
    <Grid item xs={6} style={s.rowPad}>
      <Grid item xs={12} style={s.row}>
        <Grid item xs={4} md={3}>
          <Typography style={labelStyle}>Last Sign In (UTC):</Typography>
        </Grid>
        <Grid item xs={8} md={9}>
          <UsageDate date={lastLoginDate} fmt={fmt.login} />
        </Grid>
      </Grid>
      <Box m={1.8} />
      <Grid item xs={12} style={s.row}>
        <Grid item xs={4} md={3}>
          <Typography style={labelStyle}>Last Usage (UTC):</Typography>
        </Grid>
        <Grid item xs={8} md={9}>
          <UsageDate date={lastUsageDate} fmt={fmt.usage} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const Identities = ({
  user: initialUser,
  primary,
  federated,
  activity,
  verifyPrimary,
  onUserUpdate,
  createSuccess,
}) => {
  const classes = useStyles()

  const [user, setUser] = useState(initialUser)
  const [userPoolId, setUserPoolId] = useState(user.identity.userPoolId)
  const [loading, setLoading] = useState(false)
  const [confirmDisable, setConfirmDisable] = useState(false)
  const [confirmEnable, setConfirmEnable] = useState(false)
  const [confirmNewPassword, setConfirmNewPassword] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)


  const handleStatusChange = async status => {
    setConfirmDisable(false)
    setConfirmEnable(false)

    setUser({
      ...user,
      accountStatus: status.toUpperCase(),
    })

    setLoading(true)
    await axios.put(`${USER_API}/${user.id}`, { status })

    // TODO: Add error handling on response.
    setLoading(false)
    onUserUpdate({
      ...user,
      accountStatus: status.toUpperCase(),
    })
    createSuccess("User status successfully updated!")
  }

  const handleSendNewPassword = () => {
    setPasswordLoading(true)
    axios
      .patch(`${USER_API}/${user.primaryIdentityId}/idm`, {
        isPermanent: false,
      })
      .then(res => {
        createSuccess("Password successfully reset.")
      })
      .catch(err => {
        console.log(err, "error")
        createSuccess("Something went wrong! Pease contact support.")
      })
      .finally(() => {
        setPasswordLoading(false)
        setConfirmNewPassword(false)
      })
  }

  if (!primary && !federated) {
    return (
      <div className={classes.root}>
        <Typography style={s.label}>
          No identity was found for this user. Please contact Platform Support.
        </Typography>
        <Typography style={s.label}>{userPoolId}</Typography>
      </div>
    )
  }

  const chartPaper = classNames(
    classes.paper,
    "slowfadein",
    classes.paddingBottom,
  )

  return (
    <Paper className={chartPaper}>
      <Grid container className={classes.root}>
        <EmailAddresses
          primary={primary}
          federated={federated}
          userPoolId={userPoolId}
          verifyPrimary={verifyPrimary}
        />
        {federated ? null : (
          <>
            <Activity activity={activity} /> <UserProfile primary={primary} />
          </>
        )}
        {federated ? null : (
          <>
            <Box m={1} />
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
              xs={12}
            >
              <Grid container spacing={4}>
                {user.accountStatus === "ACTIVE" ? (
                  <>
                    {/* Button */}
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.disable}
                        onClick={() => setConfirmDisable(true)}
                        disabled={
                          loading ||
                          !user.firstName ||
                          !user.lastName ||
                          user.identity.identityProvider === "EXTERNAL_PROVIDER"
                        }
                      >
                        Disable Identity
                      </Button>
                    </Grid>
                    {/* Confirm Dialog */}
                    <Dialog
                      open={confirmDisable}
                      onClose={() => setConfirmDisable(false)}
                      aria-labelledby="disable-confirm-title"
                      aria-describedby="disable-confirm-description"
                    >
                      <DialogTitle id="disable-confirm-title">
                        Confirm Disable Account
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="disable-confirm-description">
                          Disable the account for{" "}
                          <strong>
                            {user.firstName} {user.lastName}
                          </strong>{" "}
                          ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setConfirmDisable(false)}>
                          Cancel
                        </Button>
                        <Button
                          onClick={() => handleStatusChange("disabled")}
                          color="primary"
                          autoFocus
                        >
                          Disable
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <>
                    {/* Button */}
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.enable}
                        onClick={() => setConfirmEnable(true)}
                        disabled={loading || !user.firstName || !user.lastName}
                      >
                        Enable Identity
                      </Button>
                    </Grid>
                    {/* Confirm Dialog */}
                    <Dialog
                      open={confirmEnable}
                      onClose={() => setConfirmEnable(false)}
                      aria-labelledby="enable-confirm-title"
                      aria-describedby="enable-confirm-description"
                    >
                      <DialogTitle id="enable-confirm-title">
                        Confirm Enable Account
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="enable-confirm-description">
                          Enable the account for{" "}
                          <strong>
                            {user.firstName} {user.lastName}
                          </strong>{" "}
                          ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setConfirmEnable(false)}>
                          Cancel
                        </Button>
                        <Button
                          onClick={() => handleStatusChange("active")}
                          color="primary"
                          autoFocus
                        >
                          Enable
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
                <>
                  <Grid item>
                    <Button
                      style={{ minWidth: "180px" }}
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setConfirmNewPassword(true)}
                      endIcon={<SendIcon />}
                    >
                      Reset Password
                    </Button>
                  </Grid>

                  {/* Confirm New Password Dialog */}
                  <Dialog
                    open={confirmNewPassword}
                    onClose={() => setConfirmNewPassword(false)}
                    aria-labelledby="disable-confirm-title"
                    aria-describedby="disable-confirm-description"
                  >
                    <DialogTitle id="disable-confirm-title">
                      Reset Password
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="disable-confirm-description">
                        This will reset the user’s password. A temporary
                        password will be emailed that the user will have to
                        change at next login. Do you wish to proceed?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setConfirmNewPassword(false)}>
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSendNewPassword}
                        color="primary"
                        autoFocus
                      >
                        Continue
                      </Button>
                      {passwordLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </DialogActions>
                  </Dialog>
                </>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    minHeight: 320,
    padding: `${spacing(2)}px ${spacing(3)}px`,
  },
  paper: {
    height: "95%",
  },
  enable: {
    backgroundColor: "#c4df9b",
  },
  disable: {
    backgroundColor: "#e47f63",
    color: "#fff",
  },
}))

const s = {
  lightGrey: {
    color: "rgba(0, 0, 0, 0.60)",
  },
  field: {
    minHeight: "1.5rem",
    margin: "8px 0",
  },
  profile: {
    marginTop: 16,
  },
  row: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  rowPad: {
    paddingLeft: 10,
  },
  rowPhone: {
    marginTop: 8,
  },
  label: {
    minWidth: "30%",
    lineHeight: "1.2rem",
    display: "inline-block",
    fontSize: 12,
    color: grey[800],
  },
  verifyBtn: {
    padding: 0,
    borderRadius: 5,
    fontSize: ".9em",
  },
  doneIcon: {
    margin: 4,
    backgroundColor: "#85BE35",
    color: "#fff",
    borderRadius: "50%",
    fontSize: "inherit",
  },
  value: {
    flex: 1,
    fontSize: 12,
    display: "inline-flex",
    alignItems: "center",
  },

  userIdContainer: {
    overflow: "hidden",
  },
  userId: {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    borderRadius: 4,
    fontFamily: "Roboto Mono, monospace",
    fontSize: 12,
    margin: 0,
    overflow: "hidden",
    padding: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}

export default withContext(Identities)
